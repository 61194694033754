<template>
  <v-container
    fill-height
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="4"
        align="center"
      >
        <h1 class="display-2 primary--text">
          {{ $t('errors.403.title') }}
        </h1>
        <div class="subtitle-1">
          {{ $t('errors.403.subtitle') }}
        </div>
        <router-link :to="{path: '/'}">
          <v-btn
            depressed
            color="primary"
            class="mt-12"
          >
            {{ $t('errors.403.button') }}
          </v-btn>
        </router-link>
        <router-link :to="{path: '/auth/logout'}">
          <v-btn
            depressed
            color="error"
            class="mt-12 ml-5"
            :show="$can"
          >
            {{ $t('errors.403.button2') }}
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageForbidden',
  metaInfo() {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('errors.403.meta.title')} — %s`
    }
  }
}
</script>
